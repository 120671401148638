
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
//import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigXjCrmUserLog",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: {},
  setup(props, { emit }) {
    let data = reactive<any>({
      init: false,
      maps: new Map(),
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-OrderId",
      },
      title: "",
      orderId: 0,
    });

    const dialogVisible = computed({
      get() {
        return props.dialogFormVisible;
      },
      set() {
        emit("close");
      },
    });

    const searchChange = (orderId: number) => {
        data.query.where = {
            orderId: orderId,
        };
        data.orderId = orderId;
        data.page.currentPage = 1;
        data.query.page = 1;
        data.query.limit = data.page.pageSize;
        getList();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      //console.log(data.query);
      Api.Config.GetXjCrmUserLogList(data.query).then((res: any) => {
        //console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Config.GetXjCrmUserLogListOption().then((res: any) => {
        data.option = res;
        data.init = true;
      });
    };

    // const rowSave = (form: any, done: any, loading: any) => {
    //   Api.Config.AddHospital(form).then((res: any) => {
    //     if (res.Code == 0) {
    //       ElMessage.success("保存成功");
    //       getList();
    //       done();
    //     } else {
    //       loading();
    //       ElMessage.warning({
    //         showClose: true,
    //         duration: 10000,
    //         message: res.ResultMsg,
    //       });
    //     }
    //   });
    // };

    // const rowUpdate = (form: any, index: any, done: any, loading: any) => {
    //   Api.Config.EditHospital(form.HospitalID, form).then((res: any) => {
    //     if (res.Code == 0) {
    //       ElMessage.success("保存成功");
    //       getList();
    //       done();
    //     } else {
    //       loading();
    //       ElMessage.warning({
    //         showClose: true,
    //         duration: 10000,
    //         message: res.ResultMsg,
    //       });
    //     }
    //   });
    // };
    // const rowDel = (row: any) => {
    //   ElMessageBox.confirm("此操作将为用户开通该服务的体验，为期一个月, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "",
    //   })
    //     .then(() => {
    //       row.Status = 0;
    //       Api.Config.DeleteTime(row.id).then((res: any) => {
    //         if (res.Code == 0) {
    //           ElMessage.success("开通成功");
    //         } else {
    //           ElMessage.warning({
    //             showClose: true,
    //             duration: 10000,
    //             message: res.ResultMsg,
    //           });
    //         }
    //       });
    //     })
    //     .catch(() => {
    //       //console.log("xx");
    //     });
    // };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      dialogVisible,
      changePage,
      // rowSave,
      // rowUpdate,
      // rowDel,
      searchChange,
    };
  },
});
